<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.travel_request_history") }}
          <v-spacer />
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          class="elevation-1"
        >
          <template #item="row">
            <tr>
              <td><employee-profile :employee="row.item.travel.employee" /></td>
              <td class="text-center">
                {{ row.item.travel.beginDate | formattedDateTimeMinute }} -
                {{ row.item.travel.endDate | formattedDateTimeMinute }}
              </td>
              <td class="text-center">{{ row.item.travel.startWorkDate | formattedDate }}</td>
              <td class="text-center">
                <v-icon :color="row.item.travel.isDomestic ? 'green' : 'red'">{{
                  row.item.travel.isDomestic ? "mdi-check" : "mdi-close"
                }}</v-icon>
              </td>
              <td>
                {{ row.item.travel.address }}
              </td>
              <td class="text-center">
                <request-status-label :status="row.item.travel.requestStatus" />
              </td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card color="grey lighten-3" elevation="0">
                  <v-card-text class="px-4">
                    <v-tabs v-model="activeTab" slider-color="primary" background-color="grey lighten-3">
                      <v-tab href="#detail-table">
                        <v-icon color="tertiary" size="18" class="mr-2">mdi-details</v-icon>
                        {{ $t("hr.travel.travel_details") }}
                      </v-tab>
                      <v-tab href="#expense-timeline">
                        <v-icon color="tertiary" size="18" class="mr-2">tw-hr-expenses</v-icon>
                        {{ $t("hr.travel.expenses") }}
                      </v-tab>
                      <v-tab href="#imprest-timeline">
                        <v-icon color="tertiary" size="18" class="mr-2">tw-hr-cost</v-icon>
                        {{ $t("hr.travel.imprests") }}
                      </v-tab>
                      <v-tab href="#debit-table">
                        <v-icon color="tertiary" size="18" class="mr-2">mdi-layers</v-icon>
                        {{ $t("hr.travel.debits") }}
                      </v-tab>
                      <v-tab-item value="detail-table">
                        <v-card flat>
                          <v-card-text class="pa-0">
                            <travel-detail-table :items="item.travel.travelDetails" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="expense-timeline">
                        <v-card flat>
                          <v-card-text>
                            <travel-expense-timeline
                              v-show="item.travel.expenses.length"
                              :items="item.travel.expenses"
                            />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="imprest-timeline">
                        <v-card flat>
                          <v-card-text>
                            <travel-imprest-timeline
                              v-show="item.travel.imprests.length"
                              :items="item.travel.imprests"
                            />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                      <v-tab-item value="debit-table">
                        <v-card flat>
                          <v-card-text class="pa-0">
                            <travel-debit-table :items="item.travel.debits" />
                          </v-card-text>
                        </v-card>
                      </v-tab-item>
                    </v-tabs>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2021-01-13 14:02:37
   * @modify date 2021-01-13 14:02:37
   * @desc TravelRequestHistory
   */
  import { APPROVAL_TRAVEL_REQUESTS } from "../../query";

  export default {
    name: "TravelRequestHistory",
    components: {
      RequestStatusLabel: () => import("@/components/global/request-status-label"),
      TravelExpenseTimeline: () => import("../travel/components/TravelExpenseTimeline"),
      TravelImprestTimeline: () => import("../travel/components/TravelImprestTimeline"),
      TravelDebitTable: () => import("../travel/components/TravelDebitTable"),
      TravelDetailTable: () => import("../travel/components/TravelDetailTable")
    },
    data: vm => ({
      activeTab: 0,
      table: {
        loading: false,
        data: [],
        criteria: {},
        options: {},
        pagination: {
          rowsPerPage: 10,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("global.employee"),
            value: "imprest.employee.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.travel_dates"),
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.start_work_date"),
            value: "startWorkDate",
            align: "center",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.is_domestic"),
            value: "isDomestic",
            align: "center",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("global.address"),
            align: "left",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.travel.status"),
            value: "requestStatus",
            align: "center",
            sortable: false,
            class: ["primary--text"]
          },
          {
            text: "",
            align: "center",
            sortable: false
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      async fetchItems() {
        this.table.loading = true;
        await this.$apollo
          .query({
            query: APPROVAL_TRAVEL_REQUESTS,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!errors && !data.error) {
              this.table.data = data.employee.approvalTravelRequests || [];
              this.table.totalItems = data.employee.approvalTravelRequests.length || 0;
            }
          })
          .catch(e => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
